// extracted by mini-css-extract-plugin
export var carouselContainer = "D_vK d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_vL D_vK d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_vM d_w d_H d_Z";
export var prevCarouselItem = "D_vN d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vP D_vN d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vQ";
export var prevCarouselItemR = "D_vR D_vN d_w d_H d_0 d_k";
export var moveInFromRight = "D_vS";
export var selectedCarouselItem = "D_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vV D_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vW D_vT d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vX d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vY D_vX d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vZ D_vX d_w d_H d_0 d_k";
export var arrowContainer = "D_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_v1 D_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_v2 D_v1 D_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_v3 D_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_v4";
export var nextArrowContainerHidden = "D_v5 D_v3 D_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_v6 D_kG d_0";
export var nextArrow = "D_v7 D_kG d_0";
export var carouselIndicatorContainer = "D_v8 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_v9 d_w d_bz d_bF";
export var carouselText = "D_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_wc D_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_wd d_b7";
export var carouselIndicator = "D_wf D_wd d_b7";
export var carouselIndicatorSelected = "D_wg D_wd d_b7";
export var arrowsContainerTopRight = "D_wh d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_wj d_0 d_bl d_bC";
export var arrowsContainerSides = "D_wk d_0 d_bl d_bC";
export var smallArrowsBase = "D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_wm D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_wn D_wm D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_wp D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_wq D_wp D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_p9";
export var iconSmall = "D_wr";
export var multipleWrapper = "D_ws d_bC d_bF d_bf";
export var multipleImage = "D_wt d_bC";
export var sidesPrevContainer = "D_wv D_wm D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_ww D_wm D_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";