// extracted by mini-css-extract-plugin
export var alignLeft = "v_qD d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_qF d_fr d_bH d_dx";
export var container = "v_mw d_dW d_Z";
export var containerFull = "v_th d_dT d_Z";
export var carouselWrapper = "v_k6 d_w d_bz d_bD";
export var design1Elements = "v_tj";
export var step1 = "v_tk";
export var step1ContentWrapper = "v_tl";
export var step2 = "v_tm d_b5";
export var top1 = "v_tn d_w d_bC d_bP d_cs";
export var top2 = "v_tp v_tn d_w d_bC d_bP d_cs";
export var design2 = "v_tq";
export var line = "v_fk d_w";